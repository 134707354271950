.ck-editor__editable {
    height: 20rem;

    & > * + * {
        margin-top: 1.5rem;
    }
}

.customRichTextContent {
    & > div > * + * {
        margin-top: 1.5rem;
    }
}

.customRichTextContent,
.ck-editor__editable {
    ol,
    ul {
        padding-left: 1.5rem;
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }

    li {
        padding: 0;
        margin: 0;

        & + li {
            padding-top: 1rem;
        }
    }
}
