@use "/src/scss/abstracts" as *;

.form {
    &.grid-12 {
        gap: 3rem 2rem;
    }

    &__actions {
        border-top: 1px solid rgba($black, 0.1);
        padding-top: 1rem;
    }
}
