@use "/src/scss/abstracts" as *;

.button-group {
    display: flex;
    border-bottom: 1px solid $gray-200;
    margin-bottom: $spacing-400;

    &__item {
        display: block;
        padding: 0.75rem 1rem 0.25em;
        color: $primary;
        background-color: $white;
        font-weight: bold;
        transition: all 200ms ease;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border: 1px solid $primary;
        border-bottom: none;
        appearance: none;

        &:hover {
            cursor: pointer;
            color: $white;
            background-color: $primary;
        }

        &.active {
            background: $primary;
            color: $white;
            cursor: pointer;

            &:hover {
                color: $white;
            }
        }
    }
}
