@use "/src/scss/abstracts" as *;

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.125);

    &:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }

    &:last-child {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    & + & {
        border-top-width: 0;

        &.active {
            margin-top: -1px;
            border-top-width: 1px;
        }
    }
}
