@use "/src/scss/abstracts" as *;
.btn {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border-radius: 0.375rem;
    border: none;
    font-family: $lato;
    font-size: $type-size-200;
    font-weight: 400;
    letter-spacing: 0.045em;
    line-height: 1;
    padding: 0.875rem 1.25rem 0.675rem;
    transition: all 300ms ease;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075);

    &:focus,
    &.focus {
        outline: 0;
    }

    &:hover {
        cursor: pointer;
    }

    @include btn-disabled;

    &-link,
    &-underline {
        box-shadow: unset;
    }

    &-text {
        box-shadow: unset;
        color: $primary;
        padding: 0;
        font-weight: 400;
        transition: all 0s;
        &:hover {
            text-decoration: underline;
        }
    }

    .fa,
    .fas {
        display: inline-block;
        margin-right: 0.25rem;
        font-size: 0.875em;

        &.icon-right {
            margin-right: unset;
            margin-left: 0.25rem;
        }
    }

    &-success {
        background-color: $green-default;
        color: $white;
        border: 1px solid $green-dark;

        &:hover {
            background: darken($green-default, 5%);
        }

        &-outline {
            border: 1px solid $green-dark;
            background: none;
            color: $green-default;

            &:hover {
                background: $green-default;
                color: $white;
            }
        }
    }

    &-danger {
        background-color: $red-default;
        color: $white;
        border: 1px solid $red-dark;

        &:hover {
            background: darken($red-default, 5%);
        }

        &-outline {
            border: 1px solid $red-dark;
            background: none;
            color: $red-default;

            &:hover {
                background: $red-default;
                color: $white;

                svg path {
                    fill: $white;
                }
            }
        }
    }

    &-primary {
        color: $white;
        border: 1px solid darken($neptune-orange, 4%);
        text-shadow: 0px 1px 2px rgba($neptune-orange, 0.8);
        background: linear-gradient(0deg, lighten($neptune-orange, 2%) 0%, lighten($neptune-orange, 0%) 100%);

        &:hover {
            color: $white;
            background: linear-gradient(0deg, darken($neptune-orange, 0%) 0%, darken($neptune-orange, 2%) 100%);
        }

        &-outline {
            background: $white;
            color: $neptune-orange;
            border: 1px solid $neptune-orange;

            &:hover {
                background: $neptune-orange;
                color: $white;
                border: 1px solid $neptune-orange;
                svg path {
                    fill: $white;
                }
            }

            @include btn-disabled;
        }
    }

    &-secondary {
        color: $white;
        border: 1px solid darken($secondary, 4%);
        text-shadow: 0px 1px 2px rgba($secondary, 0.8);
        background: linear-gradient(0deg, lighten($secondary, 2%) 0%, lighten($secondary, 0%) 100%);

        &:hover {
            background: linear-gradient(0deg, darken($secondary, 0%) 0%, darken($secondary, 2%) 100%);
            color: $white;
        }

        &-outline {
            background: transparent;
            color: $secondary;
            border: 1px solid $secondary;

            &:hover {
                background: $secondary;
                color: white;
                border: 1px solid $secondary;
            }

            @include btn-disabled;
        }
    }

    &-orange {
        color: $white;
        border: 1px solid darken($orange-default, 4%);
        text-shadow: 0px 1px 2px rgba($orange-default, 0.8);
        background: linear-gradient(0deg, lighten($orange-default, 2%) 0%, lighten($orange-default, 0%) 100%);

        &:hover {
            color: $white;
            background: linear-gradient(0deg, darken($orange-default, 0%) 0%, darken($orange-default, 2%) 100%);
        }

        &-outline {
            background: $white;
            color: $orange-default;
            border: 1px solid $orange-default;

            &:hover {
                background: $orange-default;
                color: $white;
                border: 1px solid $orange-default;
                svg path {
                    fill: $white;
                }
            }

            @include btn-disabled;
        }
    }

    &-danger {
        background: $red-default;
        color: $white;

        &:hover {
            background-color: darken($red-default, 10%);
            color: $white;
        }
    }

    &-muted {
        background: none;
        border: none;
        color: $gray-default;
        box-shadow: none;
        &:hover {
            box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075);
            color: $black;
        }
    }

    &-lg {
        font-weight: 600;
        font-size: $type-size-150;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        border-radius: 4px;
        line-height: 1.5em;
        padding: 1rem 1.5rem 1rem;

        i {
            display: inline-block;
            padding-left: 0.5rem;
            transform: translateY(1px);
            font-size: 1.125em !important;
        }
    }

    &-md {
        height: 3rem;
        line-height: 1.675em;
    }

    &-sm {
        font-size: 0.875rem;
        padding: 0.6125rem 0.875rem 0.4125rem;
        line-height: 1;
    }

    &-rounded {
        border-radius: 100px;
    }

    &-underline {
        font-size: $type-size-100;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    &-white-outline {
        color: $white;
        border-color: $white;

        &:hover {
            background: $white;
            color: $primary;
        }
    }
}

button.btn:not(.btn-sm) {
    padding-bottom: 0.75rem;
}

.filter-btn-group {
    &.align-right {
        display: flex;
        justify-content: flex-end;
    }
}
