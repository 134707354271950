.scroll-shadow {
    background: /* Shadow covers */
        linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */
        linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size:
        100% 40px,
        100% 40px,
        100% 14px,
        100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

.scroll-shadow-x {
    background: 
	
    /* Shadows */
        linear-gradient(to right, white, white),
        linear-gradient(to right, white, white),
        /* Shadow covers */ linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
        linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

    background-position:
        left center,
        right center,
        left center,
        right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size:
        20px 100%,
        20px 100%,
        10px 100%,
        10px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}
