@use "/src/scss/abstracts" as *;

dialog {
    position: fixed;
    inset-block-start: 0px;
    inset-block-end: 0px;
    max-width: calc((100% - 6px) - 2em);
    max-height: calc((100% - 6px) - 2em);
    user-select: text;
    visibility: visible;
    overflow: auto;
    z-index: 100;

    padding: 0;
    border: none;
    box-shadow: $shadow-100;
    overflow: auto;

    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 1rem;
    }

    &.primary {
        .modal-header {
            background-color: $primary;
            color: $white;
            .close {
                color: $white;
            }
        }
        .modal-footer {
            border-top: 1px solid $primary;
        }
    }

    &.light {
        padding: $spacing-600;
        border-radius: 8px;
        background-color: $white;
        .modal-header {
            background-color: $white;
            color: $black;
            padding: 0 0 $spacing-500 0;

            border-bottom: 1px solid $tan;
            .close {
                color: $black;
                font-size: 30px;
                // position: absolute;
                // top: 1.25rem;
                // right: 1rem;
            }
        }

        .modal-body {
            padding: $spacing-500 0;
            // padding:  0;
        }

        .modal-footer {
            background-color: $white;

            border-top: 2px solid $tan;
            padding: $spacing-500 0 0 0;
        }
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .close {
            line-height: inherit;
            border: none;
            background: none;
            opacity: 0.5;
            align-self: start;
            font-size: 1.25em;

            &:hover {
                opacity: 1;
            }
        }
        h3 {
            font-size: $type-size-500;
        }
        p {
            font-size: $type-size-300;
            line-height: 1.25em;
        }
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }
}
