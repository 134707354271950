@use "/src/scss/abstracts" as *;

.grid-9 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(9, minmax(0, 1fr));
    align-items: start;

    @include desktop-medium {
        grid-template-columns: repeat(18, minmax(0, 1fr));
    }

    & > * {
        grid-column: 1 / -1;
        min-width: 0px;
    }

    @for $i from 1 through 9 {
        & > .g-col-#{$i} {
            @include desktop-medium {
                grid-column: span $i * 2;
            }
        }
    }

    .g-col-half {
        @include desktop-medium {
            grid-column: span 9;
        }
    }
}
