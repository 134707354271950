@use "/src/scss/abstracts" as *;

.inline-help {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    border: 1px solid rgba($black, 0.1);
    background: rgba($black, 0.03);
    padding: 1rem;

    .icon {
        padding-right: 1.5rem;

        i {
            font-size: $type-size-400;
            color: $primary;
        }
    }

    p + p {
        margin-top: 0.5rem;
    }
}
