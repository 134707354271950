@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
/*

    OVERPASS
    400, 400i
    700, 700i
    900, 900i

    OVERPASS MONO
    400
    700

*/

$lato: "Lato", sans-serif;

$header-nav-link-font-weight: inherit;

$font-family-sans-serif: $lato;
$font-size: 16px;
